import React from "react"
import LogoSpring from './LogoSpring'

export default function Home() {
  return (
    <div>
      <LogoSpring />
      <div className="title-cont">
        <h2>WHERE THE SKY'S NOT THE LIMIT</h2>
        <h1>It's not just software. It's doing your work!</h1>
      </div>
    </div>
  )
}
